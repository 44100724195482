import isChildBitwiseForStrings from 'ati-utils/isChildBitwiseForStrings';
import BigNumber from 'bignumber.js';
import { format } from 'date-fns';
import { makeAutoObservable } from 'mobx';

import { extraParams } from 'constants/extraParams';
import { currenciesModifiers, currenciesRateTypes, paymentTypes } from 'constants/payment';
import { Geo } from 'store/FilterStore/Geo';
import { FilterType } from 'store/FilterStore/types';
import { RootStore } from 'store/RootStore';
import { bitSumToArray } from 'utils/numbers';
import { capitalize, interpolate, uncapitalize } from 'utils/string/format';

export class SavedFilterSummary {
  root: RootStore;
  filter?: FilterType;
  to: string;
  from: string;
  firmGeo: string;

  constructor(root: RootStore, filter?: FilterType) {
    this.filter = filter;
    this.root = root;

    this.to = '';
    this.from = '';
    this.firmGeo = '';

    makeAutoObservable(this);

    this.restoreGeoNames();
  }

  restoreGeoNames = async () => {
    const { geoLists } = this.root.dictionaries;

    const geoNamesToRestore = ['from', 'to', 'firmGeo'] as const;

    const restoredGeoNames = await Promise.allSettled([
      this.filter?.from && Geo.getRestoredGeoNameById(this.filter.from, geoLists),
      this.filter?.to && Geo.getRestoredGeoNameById(this.filter.to, geoLists),
      this.filter?.firm?.firmGeo?.id &&
        typeof this.filter?.firm?.firmGeo?.type === 'number' &&
        Geo.getRestoredGeoNameById(this.filter.firm.firmGeo, geoLists),
    ]);

    restoredGeoNames.forEach((restoredGeoName, index) => {
      if (restoredGeoName.status === 'fulfilled' && restoredGeoName.value) {
        this[geoNamesToRestore[index]] = restoredGeoName.value;
      }
    });
  };

  static i18nRatingMap: { [key: number]: 'five' | 'four' | 'three' | 'two' | 'one' } = {
    5: 'five',
    4: 'four',
    3: 'three',
    2: 'two',
    1: 'one',
  };

  private getMinMaxField({ type, unit }: MinMaxFields): string {
    const i18n = this.root.app.i18n;

    const min = this.filter?.[type]?.min;
    const max = this.filter?.[type]?.max;

    return [min && `${i18n.common.from} ${min}`, max && `${i18n.common.to} ${max}`, (min || max) && i18n.common[unit]]
      .filter(Boolean)
      .join(' ');
  }

  get loadDate(): string {
    const { dateOption, dateFrom, dateTo } = this.filter?.dates ?? {};
    if (dateOption === 'manual') {
      const fromDate = dateFrom ? format(new Date(dateFrom), 'dd.MM.yyyy') : null;
      const toDate = dateTo ? format(new Date(dateTo), 'dd.MM.yyyy') : null;

      let result = '';

      if (fromDate) {
        result += fromDate;
      }

      if (toDate) {
        result += ` — ${toDate}`;
      }

      return result;
    }

    const selectedDate = this.root.filter.loadDate.dateOptions.find(date => date.id === dateOption);

    return selectedDate?.caption || '';
  }

  get routeParams(): { ellipsis?: string; routeLength?: string } {
    const unitLabels = this.root.options.getEllipseDistance();
    const maxEnlargmentUnit = unitLabels.find(unit => unit.value === this.filter?.ellipse?.maxEnlargmentUnit);
    const minLengthUnit = unitLabels.find(unit => unit.value === this.filter?.ellipse?.minLengthUnit);

    const i18n = this.root.app.i18n;

    const summary: { ellipsis?: string; routeLength?: string } = {};

    if (this.filter?.ellipse) {
      summary.ellipsis = interpolate(i18n.routeParams.ellipsSummary, {
        increase: `${this.filter.ellipse.maxEnlargment} ${maxEnlargmentUnit?.label}`,
        distance: `${this.filter.ellipse.minLength} ${minLengthUnit?.label}`,
      });
    }

    if (this.filter?.routeLength && (this.filter?.routeLength?.min || this.filter.routeLength?.max)) {
      const { min, max } = this.filter.routeLength;

      summary.routeLength = capitalize(
        [
          min && interpolate(i18n.routeParams.routeMinLength, { min: `${min} ${i18n.common.km}` }),
          max && interpolate(i18n.routeParams.routeMaxLength, { max: `${max} ${i18n.common.km}` }),
        ]
          .filter(Boolean)
          .join(', '),
      );
    }

    return summary;
  }

  get weight() {
    return this.getMinMaxField({ type: 'weight', unit: 'tons' });
  }

  get volume() {
    return this.getMinMaxField({ type: 'volume', unit: 'meter' });
  }

  get length() {
    return this.getMinMaxField({ type: 'length', unit: 'meter' });
  }

  get width() {
    return this.getMinMaxField({ type: 'width', unit: 'meter' });
  }

  get height() {
    return this.getMinMaxField({ type: 'height', unit: 'meter' });
  }

  get loadType(): string {
    const loadTypes = this.root.options.getLoadTypes();
    const selectedLoadType = loadTypes.find(type => type.value === this.filter?.dogruz);

    return selectedLoadType?.label || '';
  }

  get sortingType(): string {
    const sortingTypes = this.root.options.getSortingTypes();
    const selectedSortingType = sortingTypes.find(type => this.filter?.sortingType === type.value);

    return selectedSortingType?.label || '';
  }

  get changeDate(): string {
    const changeDateTypes = this.root.options.getChangeDate();
    const selectedChangeDateType = changeDateTypes.find(type => this.filter?.changeDate === type.value);

    return selectedChangeDateType?.label || '';
  }

  // Генерим короткое суммари по типам кузовов. Если выбран какой то родительский тип,
  // то его детей не упоминаем.
  get truckType(): string {
    const truckTypes = bitSumToArray(this.filter?.truckType?.toString() || '').map(type => type.toString());

    // Если есть все дети родительских типов, то добавляем сами эти родительские типы.
    const withParents = this.root.filter.truckType.addParentCarTypes(
      this.root.dictionaries.carTypesWithChildren,
      truckTypes,
    );

    // Выбираем получившиеся родительские типы
    const parentCarTypes = this.root.dictionaries.carTypesWithChildren
      .filter(ctwc => withParents.includes(ctwc.mask))
      .map(item => new BigNumber(item.mask).toString(2));

    // Оставляем только родительские типы
    const withoutChildren = withParents.filter(type => {
      const child = new BigNumber(type).toString(2);

      return !parentCarTypes.some(parent => parent !== child && isChildBitwiseForStrings(parent, child));
    });

    return withoutChildren
      .map(wc => {
        const type = this.root.dictionaries.data.carTypes.find(t => t.mask === wc);
        return type?.name;
      })
      .filter(Boolean)
      .join(', ');
  }

  get loadingTypes(): string {
    const loadingTypes = bitSumToArray(this.filter?.loadingType?.toString() || '').map(type => type.toString());

    return this.root.dictionaries.data.loadingTypes
      .filter(loadingType => loadingTypes.includes(loadingType.id.toString()))
      .map(loadingType => loadingType.name)
      .join(', ');
  }

  get cargoTypes(): string {
    if (this.filter?.cargoTypes?.length) {
      return this.filter?.cargoTypes
        .map(cargoTypeId => {
          const opt = this.root.dictionaries.data.cargoTypes.find(
            dictCargoType => dictCargoType.id.toString() === cargoTypeId.toString(),
          );

          return opt?.name;
        })
        .filter(Boolean)
        .join(', ');
    }

    return '';
  }

  get rate(): string {
    const selectedPaymentOptions: string[] = [];
    const params = bitSumToArray(this.filter?.extraParams?.toString() || '').map(param => param.toString());

    params.forEach(extraParamId => {
      const selectedOption = this.root.options
        .getPaymentTypes()
        .find(paymentTypeOption => paymentTypeOption.id === extraParamId);

      if (selectedOption && this.filter) {
        let value = uncapitalize(selectedOption.name);

        const { currencyId, rateTotal, ratePerKm, ratePerHour } = this.filter.rate ?? {};

        if (extraParamId === paymentTypes.withRate && currencyId && rateTotal) {
          const currencies = this.root.options.getRateTypes();
          const modifier = params.includes(extraParams.isRateInThousands)
            ? currenciesModifiers.thousands
            : currenciesModifiers.default;

          const currency = currencies.find(
            currency => currency.modifier === modifier && currency.currencyId === currencyId,
          );

          const currencyPerKm = this.root.filter.rate.getCurrencyByRateType(currencyId, currenciesRateTypes.perKm);
          const currencyPerHour = this.root.filter.rate.getCurrencyByRateType(currencyId, currenciesRateTypes.perHour);

          const rateSummary = [
            ratePerHour
              ? ` ${this.root.app.i18n.common.from} ${ratePerHour} ${currencyPerHour?.label}`
              : ` ${this.root.app.i18n.common.from} ${rateTotal / parseInt(modifier)} ${currency?.label}`,

            ratePerKm && ` ${this.root.app.i18n.common.from} ${ratePerKm} ${currencyPerKm?.label}`,
          ]
            .filter(Boolean)
            .join(',');

          // "со ставкой от стольки-то денег за..."
          value += rateSummary;
        }

        selectedPaymentOptions.push(value);
      }
    });

    return selectedPaymentOptions.join(', ');
  }

  get extraParams(): string {
    const selectedOptions: string[] = [];
    const params = bitSumToArray(this.filter?.extraParams?.toString() || '').map(param => param.toString());

    if (this.filter?.excludeTenders) {
      params.push(extraParams.tenders);
    }

    if (this.filter?.teamDriving) {
      params.push(extraParams.teamDriving);
    }

    if (this.filter?.adr) {
      params.push(extraParams.withAdr);
    }

    params.forEach(extraParamId => {
      const selectedOption = this.root.options
        .getExtraParams()
        .find(extraParamOption => extraParamOption.id === extraParamId);

      if (selectedOption) {
        if (selectedOption.id === extraParams.withAdr) {
          selectedOptions.push(uncapitalize(this.withAdr));
        } else {
          selectedOptions.push(uncapitalize(selectedOption.name));
        }
      }
    });

    return selectedOptions.join(', ');
  }

  get withAdr(): string {
    const i18n = this.root.app.i18n;
    const withADR = this.filter?.adr;
    const label =
      i18n.extraParams.options.adr.label.charAt(0).toLowerCase() + i18n.extraParams.options.adr.label.slice(1);
    const summary = [label];

    if (this.filter?.adr?.ids) {
      summary.push(this.filter.adr.ids.join(', '));
    }

    return withADR ? summary.join(': ') : '';
  }

  get pallets(): string {
    return this.filter?.pallets?.toString() || '';
  }

  get boards(): { boards: string; auction: string } {
    const auction = this.filter?.withAuction ? this.root.ui.i18n.boards.auctionOnly : '';
    const isAllBoardsSelected = !this.filter?.boardList?.length;

    let boards;
    if (isAllBoardsSelected) {
      boards = this.root.ui.i18n.boards.allBoards;
    } else {
      const allBoards = this.root.filter.boards.boards;
      const selectedBoards = allBoards.filter(board => {
        return this.filter?.boardList?.includes(board.id);
      });

      boards = selectedBoards.map(b => b.name).join(', ');
    }

    return { boards, auction };
  }

  get firms(): { stars: string; from: string; firm: string; list: string } {
    const i18n = this.root.ui.i18n;
    const { firmRating, firmName, firmListsExclusive, firmListsInclusive } = this.filter?.firm ?? {};

    const stars = (firmRating && i18n.firms.rating[SavedFilterSummary.i18nRatingMap[firmRating]]) || '';
    // TODO:
    const from = this.firmGeo;
    const firm = firmName || '';

    const hasExclusiveList = firmListsExclusive?.length;
    const selectedLists = hasExclusiveList ? firmListsExclusive : firmListsInclusive;

    let list = '';
    if (selectedLists?.length) {
      const listMode = hasExclusiveList ? i18n.firms.exceptFromList : i18n.firms.allFromList;

      const listNames = selectedLists
        .map(listId => this.root.dictionaries.data.privateFirmLists.all.lists.find(list => list.id === listId)?.name)
        .filter(Boolean)
        .join(', ');

      list = `${listMode} ${listNames}`;
    }

    return { stars, from, firm, list };
  }

  get showCompactSummary(): boolean {
    return (
      [
        this.from,
        this.to,
        this.filter?.weight?.min,
        this.filter?.weight?.max,
        this.filter?.volume?.min,
        this.filter?.volume?.max,
        this.filter?.dogruz,
        this.truckType,
        this.withAdr,
        this.cargoTypes,
      ].filter(Boolean).length > 0
    );
  }
}

interface MinMaxFields {
  type: 'weight' | 'volume' | 'length' | 'width' | 'height';
  unit: 'tons' | 'meter';
}
