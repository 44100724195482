export const extraParams = {
  withKoniki: '256',
  adr: '64', // TODO: при удалении сайтфичи 3838 надо избавиться от 64 и перейти на новый ниже
  withAdr: 'withAdr',
  roundTrip: '4',
  permanent: '128',
  tenders: 'excludeTenders',
  isRateInThousands: '4096',
  teamDriving: 'teamDriving',
};
