import { SeverityLevel } from '@sentry/types';

import { getInitialData } from 'utils/getInitialData';

const environment = getInitialData('buildEnv', 'dev');

class Logger {
  hub!: Errno;

  constructor() {
    if (typeof window !== 'undefined' && window.Errno) {
      const hub = new window.Errno({
        allowUrls: ['https?://(loads.)?ati.[^.s/]+/(_next/)?static/.*'],
        ignoreErrors: ['yaCounter', 'window.cb', 'VK is not defined', '__ym__prom'],
        denyUrls: [/ssi\/templates\/deals-ssi/, /userscript/, /drivers-kit/, /static\/header\/header.js/, /gtm\.js/i],
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        environment,
        release: process.env.NEXT_PUBLIC_RELEASE,
        sampleRate: 0.1,
        tracesSampleRate: 0.01,
        autoSessionTracking: false,
        integrations: [
          // @ts-expect-error BrowserTracing does not exist
          // скорее всего из-за разных версий глобального бандла и локального пакета
          new window.Sentry.Integrations.BrowserTracing(),
        ],
      });

      this.hub = hub;
    }
  }

  log = (message: string, level?: SeverityLevel) => {
    try {
      this.hub?.captureMessage(message, level);
    } catch (error) {}
  };

  error = (exception: Error, context?: TErrnoOptions) => {
    try {
      this.hub?.captureException(exception, context);
    } catch (error) {}
  };
}

export default new Logger();
