import isAfter from 'date-fns/isAfter';

import { maxCompletionListLength } from 'api/geo';
import { TSubscription, TValidationResult } from 'api/userFilters/types';
import { geoSuggestionTypes } from 'constants/geo';
import { GeoListType, LocalityType } from 'types/cargosApp/Geo';

export const hasActivePromotion = (subscription?: TSubscription | TValidationResult | null): boolean => {
  if (!subscription) return false;

  let promotionEndDate;

  if ('subscriptionPromotionEndDate' in subscription) {
    promotionEndDate = subscription.subscriptionPromotionEndDate;
  }

  if ('promotionSubscriptionEndDate' in subscription) {
    promotionEndDate = subscription.promotionSubscriptionEndDate;
  }

  if (promotionEndDate) {
    const endDate = new Date(promotionEndDate);
    const hasPromotionSubscription = isAfter(endDate, new Date());

    return hasPromotionSubscription;
  }

  return false;
};

export const fillGeoCompletionByGeoLists = ({
  prefix,
  data,
  geoLists,
}: {
  prefix: string;
  data: LocalityType[];
  geoLists: GeoListType[];
}) => {
  const filteredLists = geoLists.filter(list => list.name.toLocaleLowerCase().includes(prefix.toLocaleLowerCase()));
  const mappedGeoLists = filteredLists.map(list => ({
    id: list.id,
    type: geoSuggestionTypes.list,
    text: list.name,
  }));
  const filledWithGeoLists = [...data, ...mappedGeoLists].slice(0, maxCompletionListLength);

  return filledWithGeoLists;
};
