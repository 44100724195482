import { makeAutoObservable, reaction } from 'mobx';

import { transformGeoCompletionList } from 'api/adapters/geo';
import { geoTypesMap, getGeoCompletionList, getGeoDirections, getGeosByIds, maxCompletionListLength } from 'api/geo';
import { TGeoSuggestionValues, geoSuggestionTypes } from 'constants/geo';
import { IntegerField } from 'store/fileds/IntegerField';
import { SuggestionField } from 'store/fileds/Suggestion';
import { fillGeoCompletionByGeoLists } from 'store/utils';
import type { GeoListType, LocalityType } from 'types/cargosApp/Geo';

import { RootStore } from '../../RootStore';
import { FilterGeoType } from '../types';

interface IGeo {
  radius: IntegerField;
  locality: SuggestionField<LocalityType>;
  list: GeoListType | null;
  isExact: boolean;
}

class Geo implements IGeo {
  root;
  locality;
  radius;
  list: GeoListType | null;
  isManuallyExact;

  constructor(root: RootStore) {
    this.root = root;
    this.radius = new IntegerField();
    this.locality = new SuggestionField<LocalityType>({
      rootStore: root,
      onFetch: this.fetchGeoCompletionList,
      onSuggestionSelect: this.handleLocalityChange,
    });
    this.list = null;
    this.isManuallyExact = false;

    reaction(
      () => this.locality.value,
      () => {
        if (this.root.app.isDataSetting) return;

        this.radius.reset();
      },
    );

    makeAutoObservable(this);
  }

  get isDefault() {
    return this.radius.isDefault && this.locality.isDefault && !this.list && !this.isManuallyExact;
  }

  get isExactDisabled() {
    return !this.hasLocality || this.isAutoExact;
  }

  get isValid() {
    return this.list?.id || this.locality.suggestion?.id;
  }

  get error() {
    return this.getError();
  }

  getError(options = { isVirtuallySubmitted: false }) {
    const {
      routeParams,
      extraParams,
      truckType,
      from,
      to,
      isSubmitted: isSubmittedFromStore,
      boards,
      firms,
    } = this.root.filter;

    const isSubmitted = options.isVirtuallySubmitted || isSubmittedFromStore;

    if (extraParams.withADR || truckType.hasOnlyRareTruckTypes) return false;

    if (routeParams.isEllipseActive) return isSubmitted && !this.isValid;

    if (from.isValid || to.isValid) return false;

    return (
      isSubmitted &&
      !this.isValid &&
      !boards.withAuction.data &&
      boards.commonBoard.isSelected.data &&
      !firms.firmName.suggestion &&
      (firms.isSelectedListsDefault || !firms.isExclusiveMode.isDefault)
    );
  }

  get type(): TGeoSuggestionValues {
    if (this.list?.id) return geoSuggestionTypes.list;

    return this.locality.suggestion?.type || 0;
  }

  get requestData(): FilterGeoType | null {
    if (!this.isValid) return null;

    const data = {
      id: this.locality.suggestion?.id ? Number(this.locality.suggestion?.id) : undefined,
      type: this.type,
      radius: this.radius.data,
      exactOnly: this.isExact,
      listId: this.list?.id,
      listType: this.list?.type,
    };

    return data;
  }

  get hasLocality() {
    return Boolean(this.locality.suggestion?.id);
  }

  get isCityLocality() {
    return this.locality.suggestion?.type === geoSuggestionTypes.city;
  }

  get isExact() {
    if (!this.hasLocality && !this.list) return false;

    return this.isAutoExact || this.isManuallyExact;
  }

  get isAutoExact() {
    return (
      (this.hasLocality && !this.isCityLocality) ||
      Boolean(this.list) ||
      Boolean(this.radius.value) ||
      this.root.filter.routeParams.isEllipseActive
    );
  }

  fetchGeoCompletionList = async (prefix: string) => {
    try {
      const { data } = await getGeoCompletionList(prefix, this.root.filter.localityGeoTypes);
      const adaptedData = transformGeoCompletionList(data);

      if (adaptedData.length < maxCompletionListLength) {
        const dataWithGeoLists = fillGeoCompletionByGeoLists({
          prefix,
          data: adaptedData,
          geoLists: this.root.dictionaries.geoLists,
        });

        return dataWithGeoLists;
      }

      return adaptedData;
    } catch {
      this.root.errors.setRetrievable({
        name: 'getGeoCompletionList',
        message: this.root.app.i18n.errors.getGeoCompletionList,
      });
    }
  };

  setLocality = (locality: LocalityType) => {
    this.locality.onSuggestionSelected(undefined, { suggestion: locality });

    if (locality.type !== geoSuggestionTypes.list) this.locality.setValue(locality.text);
  };

  setIsExact = (isExact: boolean) => {
    this.isManuallyExact = isExact;
  };

  toggleExact = () => {
    this.setIsExact(!this.isManuallyExact);
  };

  setList = (list: GeoListType) => {
    this.list = list;
  };

  resetList = () => {
    this.list = null;
  };

  clearInvalidList = () => {
    const isListAvailable =
      this.root.dictionaries.data.privateGeoLists.filter(privateList => privateList.id === this.list?.id).length > 0;

    if (!this.list?.elements.length || !isListAvailable) {
      this.resetList();
      this.locality.setValue('');
    }
  };

  handleListChange = (list: GeoListType) => {
    this.locality.setValue(list.name);
    this.locality.resetSuggestion();
    this.setList(list);
  };

  handleLocalityChange = (locality: LocalityType) => {
    if (locality.type === geoSuggestionTypes.list) {
      const { geoLists } = this.root.dictionaries;

      const list = geoLists.filter(({ id }) => id === locality.id)[0];

      this.handleListChange(list);
    } else {
      this.resetList();
    }
  };

  static getRestoredListNameFromId = (listId: string, geoLists: GeoListType[]) => {
    const geoList = geoLists.find(list => list.id === listId);

    return geoList?.name;
  };

  static getRestoredGeoNameById = async (geoPoint: FilterGeoType | TFirmGeo, geoLists: GeoListType[]) => {
    let geoPointId: string | number | undefined;

    if ('listId' in geoPoint) {
      geoPointId = geoPoint.listId;
    } else {
      geoPointId = geoPoint.id;
    }

    if (!geoPointId) return '';

    switch (geoPoint.type) {
      case geoSuggestionTypes.city:
      case geoSuggestionTypes.country:
      case geoSuggestionTypes.region:
        try {
          const { data } = await getGeosByIds([String(geoPointId)], geoPoint.type);
          const [geo] = data[geoTypesMap[geoPoint.type]];

          return geo.name;
        } catch (error) {
          // TODO: позже продумать вариант, как реагируем на проблему
          console.error('error: ', error);
          return '';
        }
      case geoSuggestionTypes.list:
        return Geo.getRestoredListNameFromId(String(geoPointId), geoLists);
      case geoSuggestionTypes.direction:
        try {
          const { data } = await getGeoDirections();
          const geoDirection = data.directions.find(direction => direction.id === Number(geoPointId));

          if (geoDirection) {
            return geoDirection.name;
          }

          return '';
        } catch (error) {
          // TODO: позже продумать вариант, как реагируем на проблему
          console.error('error: ', error);
          return '';
        }
      default:
        return '';
    }
  };
}

export { Geo };
