import isChildBitwiseForStrings from 'ati-utils/isChildBitwiseForStrings';
import BigNumber from 'bignumber.js';
import { makeAutoObservable, runInAction } from 'mobx';

import { getPrivateFirmsList } from 'api/firms';
import { getPrivateGeoLists, getPublicGeoLists } from 'api/geo';
import { TSupportedLocales } from 'localization/types';
import { RootStore } from 'store/RootStore';
import { GeoListType } from 'types/cargosApp/Geo';

import { DictionariesNames, Marshak } from './Marshak';
import { makeFirmFilters } from './utils/firms';

enum ListType {
  neutralLists = 0,
  positiveList = 1,
  negativeList = 2,
}

type ListElement = {
  id: string;
  ati_id: string;
  firm_name: string;
  list_id: string;
  owner_ati_id: string;
};

export type TFirmList = {
  fixed: boolean;
  elements: ListElement[];
  id: string;
  name: string;
  type: ListType;
  description: string;
  personal: boolean;
  archive_date: null | string;
  contact_id: number;
  ati_id: string;
  emoji: null | string;
};

interface IFirmGroupList {
  name: string;
  lists: TFirmList[];
}

export interface IFirmGroupLists {
  favInclusiveLists: TFirmList[];
  favExclusiveLists: TFirmList[];
  whiteLists: IFirmGroupList;
  neutralLists: IFirmGroupList;
  blackLists: IFirmGroupList;
  all: IFirmGroupList;
  totalLists: number;
}

interface IDictionaries {
  data: TData;
}

type TData = TMarshakData & {
  publicGeoLists: GeoListType[];
  privateGeoLists: GeoListType[];
  privateFirmLists: IFirmGroupLists;
};

const marshakDictionariesConfig: TMarshakConfig = {
  [DictionariesNames.carTypes]: { regular: true },
  [DictionariesNames.cargoTypes]: { regular: true },
  [DictionariesNames.loadingTypes]: { regular: true },
  [DictionariesNames.currencyTypes]: { regular: true, byIds: true },
  [DictionariesNames.packTypes]: { byIds: true },
  [DictionariesNames.moneyTypes]: { byIds: true },
  [DictionariesNames.currencyCountry]: { regular: true },
};

const initialDictionariesData = Marshak.getInitialData(marshakDictionariesConfig);

class Dictionaries implements IDictionaries {
  root: RootStore;
  data: TData = {
    ...initialDictionariesData,
    publicGeoLists: [],
    privateGeoLists: [],
    privateFirmLists: {
      favInclusiveLists: [],
      favExclusiveLists: [],
      whiteLists: { name: '', lists: [] },
      neutralLists: { name: '', lists: [] },
      blackLists: { name: '', lists: [] },
      all: { name: '', lists: [] },
      totalLists: 0,
    },
  };

  constructor(root: RootStore) {
    this.root = root;

    makeAutoObservable(this);
  }

  setPublicGeoLists = (publicGeoLists: GeoListType[]) => {
    this.data.publicGeoLists = publicGeoLists;
  };

  setPrivateGeoLists = (privateGeoLists: GeoListType[]) => {
    this.data.privateGeoLists = privateGeoLists;
  };

  setPrivateFirmLists = (data: TFirmList[]) => {
    this.data.privateFirmLists = makeFirmFilters(data, this.root.app.i18n.firms);
  };

  get geoLists() {
    return this.data.publicGeoLists.concat(this.data.privateGeoLists);
  }

  fetchDictionaries = async () => {
    await Promise.all([
      this.fetchGlossaryDictionaries(),
      this.fetchPublicGeoLists(),
      this.fetchPrivateGeoLists(),
      this.fetchPrivateFirmLists(),
    ]);
  };

  fetchPrivateFirmLists = async () => {
    if (!this.root.profile.data?.isPaidUser) return null;

    try {
      const { data } = await getPrivateFirmsList();

      if (!data) return;

      this.setPrivateFirmLists(data);
    } catch (error) {
      this.root.errors.setRetrievable({ name: 'getPrivateFirmsList', message: this.root.app.i18n.errors.getFirmsList });
    }
  };

  fetchPublicGeoLists = async () => {
    try {
      const { data } = await getPublicGeoLists();
      const normalizedLists = data.sort((a, b) => Dictionaries.sortByLocaleName(a.name, b.name, this.root.app.locale));

      this.setPublicGeoLists(normalizedLists);
    } catch (error) {
      console.error(error);
      this.root.errors.setRetrievable({
        name: 'getPublicGeoLists',
        message: this.root.app.i18n.errors.getPublicGeoLists,
      });
    }
  };

  fetchPrivateGeoLists = async () => {
    if (!this.root.profile.data?.isUser) return null;

    try {
      const { data } = await getPrivateGeoLists();
      const normalizedLists = data.sort((a, b) => Dictionaries.sortByLocaleName(a.name, b.name, this.root.app.locale));

      this.setPrivateGeoLists(normalizedLists);
    } catch (error) {
      console.error(error);
      this.root.errors.setRetrievable({
        name: 'getPrivateGeoLists',
        message: this.root.app.i18n.errors.getPrivateGeoLists,
      });
    }
  };

  fetchGlossaryDictionaries = async () => {
    try {
      // @ts-ignore
      const marshak = new Marshak(this.root.app.locale, marshakDictionariesConfig);
      const dictionaries = await marshak.fetchDictionaries();

      // у нас задвоенная валюта для бел. рубля, это мерзкий фикс исключающий ненужную
      dictionaries.currencyCountry = dictionaries.currencyCountry.filter(
        curr => !(curr.countryId === '3' && curr.id === '22'),
      );

      dictionaries.currencyTypes = dictionaries.currencyTypes
        .filter(currency => currency.cargosSearchView === '1')
        .sort((a, b) => parseInt(a.viewOrderIndex) - parseInt(b.viewOrderIndex));

      dictionaries.cargoTypes = dictionaries.cargoTypes.sort((a, b) => {
        const startsWithDigit = (str: string) => /^\d/.test(str);

        if (startsWithDigit(a.cargoName) && !startsWithDigit(b.cargoName)) return 1;
        if (!startsWithDigit(a.cargoName) && startsWithDigit(b.cargoName)) return -1;

        return Dictionaries.sortByLocaleName(a.cargoName, b.cargoName, this.root.app.locale);
      });

      runInAction(() => {
        this.data = { ...this.data, ...dictionaries };
      });
    } catch (error) {
      console.error(error);

      this.root.errors.setRetrievable({ name: 'fetchDictionary', message: this.root.app.i18n.errors.fetchDictionary });
    }
  };

  // TODO: Наверное надо вынести куда то.
  get carTypesWithChildren() {
    const dict = this.data.carTypes;
    return dict
      ?.map(type => {
        const children = dict.filter(anotherType => {
          const parent = new BigNumber(type.mask).toString(2);
          const child = new BigNumber(anotherType.mask).toString(2);

          return parent !== child && isChildBitwiseForStrings(parent, child);
        });

        return {
          ...type,
          children,
        };
      })
      .filter(type => type.children.length !== 0);
  }

  static sortByLocaleName(a: string, b: string, locale?: TSupportedLocales) {
    return a.localeCompare(b, locale);
  }
}

export { Dictionaries };
