import { TGeoCompletionResponse } from 'api/geo/types';
import { geoSuggestionTypes, reversedNewGeoSuggestionTypes } from 'constants/geo';
import { LocalityType } from 'types/cargosApp/Geo';

export const transformGeoCompletionList = (data: TGeoCompletionResponse): LocalityType[] => {
  const { suggestions } = data;

  return suggestions.map(suggestion => {
    const geoType = reversedNewGeoSuggestionTypes[suggestion.type];
    const oldGeoType = geoSuggestionTypes[geoType];
    const geoPointId = suggestion[geoType]?.id;

    return {
      text: suggestion.address,
      type: oldGeoType,
      id: String(geoPointId),
    };
  });
};
