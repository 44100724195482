export const atiSearchGeoStorageName = 'AtiSearchGeo';

export const excludedReferrer = 'ati.s';

export const geoSuggestionTypes = {
  none: -100,
  direction: -1,
  country: 0,
  region: 1,
  city: 2,
  all: 3,
  district: 4,
  list: 5,
} as const;

export const reversedNewGeoSuggestionTypes = {
  1: 'city',
  2: 'region',
  4: 'country',
  8: 'direction',
} as const;

export type TNewGeoSuggestionTypes = typeof reversedNewGeoSuggestionTypes;
export type TReversedNewGeoSuggestionKeys = keyof typeof reversedNewGeoSuggestionTypes;

export type TGeoSuggestionTypes = typeof geoSuggestionTypes;

export type TGeoSuggestionKeys = keyof typeof geoSuggestionTypes;
export type TGeoSuggestionValues = typeof geoSuggestionTypes[TGeoSuggestionKeys];
